<template>
  <div>
    <!--Table Page Component-->
    <TestComponent @create-submit="submitCreateModal"
      @edit-submit= "submitEditModal"
      @delete-submit = "submitDeleteModal"
      @edit-show = "showEditModal"
      @create-show = "showCreateModal"
      tableTitle="Manage Endpoints"
      :data="endpoints"
      :objType="{one: 'endpoint', multi: 'endpoints'}"
      nameField="deviceType"
      :formFields="[
        {name: 'endpoint', type:'text', required:true, label: 'Endpoint URL', placeholder: '/axis/user/date?action=get'},
        {name: 'deviceType', type:'text', required:true, label: 'Device Type'},
        {name: 'make', type:'text', required:true, label: 'Make'},
        {name: 'model', type:'text', required:false, label: 'Model'}
       ]"
       :columns="['endpoint', 'deviceType', 'make', 'model', 'edit']"
       :headings="{endpoint: 'Endpoint URL', deviceType: 'Device Type', make: 'Make', model: 'Model', edit: 'Edit'}"
       :sortable="['endpoint', 'deviceType', 'make', 'model']"
       :filterable="['endpoint', 'deviceType', 'make', 'model']"
       :orderBy="{column: 'endpoint', ascending: true}">

      <div slot= "createModalInput">
        <codemirror ref="create" v-model="createCode" :options="editorOption"></codemirror>
        <br>
      </div>

      <div slot= "editModalInput">
        <label class="form-check-label">Enter Endpoint Code:</label>
        <codemirror ref="edit" v-model="editCode" :options="editorOption"></codemirror>
        <p class="text-muted">Code must be of type object</p>
        <br>
      </div>

    </TestComponent>

  </div>


</template>

<script>
  import fcr from '../../services/fcr.js'
  import TestComponent from './/Component.vue'
  import Vue from 'vue'
  import VueCodeMirror from 'vue-codemirror'

  // require styles
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/material.css'
  import 'codemirror/mode/markdown/markdown'
  import 'codemirror/mode/javascript/javascript'
  import 'codemirror/mode/xml/xml'

  // global use
  Vue.use(VueCodeMirror)

  export default {
    components: {
      TestComponent,
      VueCodeMirror
    },
    data: () => {
      return {
        endpoints: [],
        errorMsg: {method: "", status:"", statusText: ""},
        createCode:"{}",
        editCode: "{}",
        editorOption: {
          tabSize: 2,
          styleActiveLine: true,
          lineNumbers: true,
          lineWrapping: true,
          line: true,
          mode: 'javascript',
          theme: 'material',
        },
      }
    },

    computed: {
      createCodemirror () {
        return this.$refs.create.codemirror
      },
      editCodemirror () {
        return this.$refs.edit.codemirror
      }
    },
    created() {
      //FCR Get
      this.getEndpoints();
    },

    methods: {
      //FCR put, delete, post, and other functions
      getEndpoints() {
        fcr.getEndpoints().then(response => {
          this.endpoints = response.data;
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Endpoints couldn't be retrieved: " + error.response.statusText,
            style: 'error'
          });
        })
      },
      showEditModal(item) {
        if(item.update){
          this.editCode = JSON.stringify(item.update);
        }
        else{
          this.editCode = "{}";
        }
        this.editCodemirror.setSize('100%', '50vh');
        this.editCodemirror.focus();
      },
      showCreateModal() {
        this.createCode = "{}"
        this.createCodemirror.setSize('100%', '50vh');
        this.createCodemirror.focus();

      },
      submitEditModal(endpoint) {
        try{
          endpoint.update = JSON.parse(this.editCode);
        } catch(e){
          this.$mToast({
            title: 'Syntax Error',
            text: "Please review your code: ",
            style: 'error'
          });
        }
        fcr.updateEndpoint(endpoint).then(() => {
          this.$mToast({
            title: 'Success',
            text: "Endpoint updated",
            style: 'success'
          });
          this.getEndpoints();
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Endpoint couldn't be edited: " + error.response.statusText,
            style: 'error'
          });
        })
      },
      submitDeleteModal(id, reload=true) {
        fcr.removeEndpoint(id).then(() => {

          if(reload){
            this.getEndpoints();
            this.$mToast({
              title: 'Success',
              text: "Endpoint removed",
              style: 'success'
            });
          }
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Endpoint couldn't be deleted: " + error.response.statusText,
            style: 'error'
          });
        })
      },
      submitCreateModal(endpoint) {
        try{
          endpoint.update = JSON.parse(this.createCode);
        } catch(e){
          endpoint.update = {};
          this.$mToast({
            title: 'Syntax Error',
            text: "Please review your code: ",
            style: 'error'
          });
        }
        fcr.createEndpoint(endpoint).then(() => {
          this.$mToast({
            title: 'Success',
            text: "Endpoint created",
            style: 'success'
          });
          this.getEndpoints();
        }).catch(error => {
          this.$mToast({
            title: error.response.status + ' Error',
            text: "Endpoint couldn't be created: " + error.response.statusText,
            style: 'error'
          });
        })
      },
    },
  }


</script>

<style scoped>

</style>