import axios from 'axios'

function FCR() {
    return axios.create({
        baseURL: process.env.VUE_APP_FCRENGINE,
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.mtopToken
        }
    });
}

export default {

    //Pitts
    getToggleStatus() {
        return new FCR().get('/pitts/toggle/status')
    },
    getEventModeStatus() {
        return new FCR().get('/pitts/eventmode/status')
    },
    turnEventModeOn() {
        return new FCR().get('/pitts/eventmode/on')
    },
    turnEventModeOff() {
        return new FCR().get('/pitts/eventmode/off')
    },
    getLightStatus(number) {
        return new FCR().get('/pitts/lights/status/' + number)
    },
    turnLightOn(number) {
        return new FCR().get('/pitts/lights/on/' + number)
    },
    turnLightOff(number) {
        return new FCR().get('/pitts/lights/off/' + number)
    },
    playSound(event) {
        return new FCR().get('/pitts/sound/' + event)
    },
    getAlertStatus() {
        return new FCR().get('/pitts/alert/status')
    },
    toggleAlertStatus(event) {
        return new FCR().get('/pitts/alert/toggle/' + event)
    },
    rebootFlir(flirNum) {
        return new FCR().get('/pitts/flir/' + flirNum)
    },

    //Nav
    getCurrentNav(userId) {
        return new FCR().get('/navigation/user/' + userId);
    },
    updateNav(userId, selected) {
        return new FCR().put('/navigation/user/' + userId, {navbars: selected});
    },
    createUserNav(userId, selected) {
        return new FCR().post('/navigation/user', {userId: userId, navbars: selected})
    },

    getAllNavOptions() {
        return new FCR().get('/navigation/list');
    },
    getNavInfo(userId) {
        return new FCR().get('/navigation/list/' + userId);
    },
    editNavItem(tab) {
        return new FCR().put('/navigation/' + tab._id, {
            name: tab.name,
            url: tab.url,
            icon: tab.icon,
            children: tab.children
        });
    },
    deleteNavItem(id) {
        return new FCR().delete('/navigation/' + id);
    },
    createNavItem(tab) {
        return new FCR().post('/navigation', {name: tab.name, url: tab.url, icon: tab.icon, children: tab.children});
    },

    //Kepler
    getKeplerSites() {
        return new FCR().get('/kepler');
    },
    createKeplerSite(site) {
        return new FCR().post('/kepler', {
            sitename: site.sitename,
            ipaddress: site.ipaddress,
            port: site.port,
            replicas: site.replicas,
            mongourl: site.mongourl,
            secretkey: site.secretkey,
            redisPort: site.redisPort,
            mountPoint: site.mountPoint,
            localIp: site.localIp,
            spaceportport: site.spaceportport,
            stargateIp: site.stargateIp,
            stargatePort: site.stargatePort
        })
    },
    updateKeplerSite(site) {
        return new FCR().put('/kepler/' + site._id, {
            sitename: site.sitename,
            ipaddress: site.ipaddress,
            port: site.port,
            replicas: site.replicas,
            mongourl: site.mongourl,
            secretkey: site.secretkey,
            redisPort: site.redisPort,
            mountPoint: site.mountPoint,
            localIp: site.localIp,
            spaceportport: site.spaceportport,
            stargateIp: site.stargateIp,
            stargatePort: site.stargatePort
        })
    },
    deleteKeplerSite(id) {
        return new FCR().delete('/kepler/' + id)
    },
    getDockerInfo(id) {
        return new FCR().get('/kepler/' + id)
    },
    keplerStart(id) {
        return new FCR().post('kepler/start/spaceport/' + id)
    },
    keplerRestart(id) {
        return new FCR().post('kepler/restart/spaceport/' + id)
    },
    keplerStop(id) {
        return new FCR().post('kepler/stop/spaceport/' + id)
    },
    keplerRemove(id) {
        return new FCR().delete('kepler/remove/spaceport/' + id)
    },
    keplerPrune(id) {
        return new FCR().post('kepler/prune/spaceport/' + id)
    },

    //Permissions
    listPermissions() {
        return new FCR().get('/permission/list')
    },
    addPermissions(usernames, groups, permissions) {
        return new FCR().put('/permission/many/add', {usernames: usernames, groups: groups, permissions: permissions})
    },
    removePermissions(usernames, groups, permissions) {
        return new FCR().put('/permission/many/remove', {
            usernames: usernames,
            groups: groups,
            permissions: permissions
        })
    },

    //Endpoint
    getEndpoints() {
        return new FCR().get('/device/identify/endpoint/list');
    },
    updateEndpoint(endpoint) {
        return new FCR().put('/device/identify/endpoint/' + endpoint._id, {
            endpoint: endpoint.endpoint,
            deviceType: endpoint.deviceType,
            make: endpoint.make,
            update: endpoint.update,
            model: endpoint.model
        });
    },
    createEndpoint(endpoint) {
        return new FCR().post('/device/identify/endpoint', {
            endpoint: endpoint.endpoint,
            deviceType: endpoint.deviceType,
            make: endpoint.make,
            update: endpoint.update,
            model: endpoint.model
        });
    },
    removeEndpoint(id) {
        return new FCR().delete('/device/identify/endpoint/' + id);
    },

    //oid
    getOid() {
        return new FCR().get('/fm/oid/list')
    },
    createOid(name, oid, graphType) {
        return new FCR().post('/fm/oid', {name: name, oid: oid, graph_type: graphType})
    },
    updateOid(name, oid, graph_type, id) {
        return new FCR().put('/fm/oid/' + id, {oid: oid, name: name, graph_type: graph_type})
    },
    deleteOid(id) {
        return new FCR().delete('/fm/oid/' + id);
    },

    //Camera Functions
    getFunctions() {
        return new FCR().get('/camera/functions')
    },
    postFunctions(functionInfo) {
        return new FCR().post('/camera/functions', {
            make: functionInfo.make,
            model: functionInfo.model,
            title: functionInfo.title,
            url: functionInfo.url,
            icon: functionInfo.icon,
        })
    },
    putFunctions(id, functionInfo) {
        return new FCR().put('/camera/functions/' + id, {
            make: functionInfo.make,
            model: functionInfo.model,
            title: functionInfo.title,
            url: functionInfo.url,
            icon: functionInfo.icon,
        })
    },
    deleteFunctions(id) {
        return new FCR().delete('/camera/functions/' + id)
    },

    //Intact Case Management
    getIntactCases() {
        return new FCR().get('/intact');
    },
    updateIntactCase(object) {
        return new FCR().put('/device/identify/endpoint/' + object._id, {});
    },
    createIntactCase(object) {
        return new FCR().post('/intact/request/video', {
            requests: object.cameras,
            start: object.start,
            end: object.end,
            caseId: '123'
        })

    },
    removeIntactCase(id) {
        return new FCR().delete('/intact/' + id);
    },
    urlUpload(object) {
        return new FCR().post('/intact/url/upload', {intactId: object._id, phoneNumber: object.phoneNumber})
    },

    //video Wall
    getLayouts() {
        return new FCR().get('/layout/list/' + localStorage.username);
    },
    saveLayout(name, devices, defaultLayout) {
        return new FCR().post('/layout', {
            layoutname: name,
            user: localStorage.username,
            devices: devices,
            default: defaultLayout
        });
    },
    deleteLayout(id) {
        return new FCR().delete('/layout/' + id);
    },
    updateLayout(layout) {
        return new FCR().put('/layout/' + layout._id, {layoutname: layout.layoutname, default: layout.default, devices: layout.devices});
    },
    updateDefaultLayout(layout) {
        return new FCR().put('/layout/' + layout._id, {default: layout.default});
    },

    //exports
    deleteVideo(id) {
        return new FCR().delete('/exports/' + id);
    },
    exportVideo(deviceId, constellationUrl, startTime, endTime) {
        return new FCR().post('/exports', {
            videoClip: {deviceId: deviceId, constellationUrl: constellationUrl},
            start: startTime,
            end: endTime,
            oldToken: localStorage.getItem("oldToken")
        })
    },
    getExports() {
        return new FCR().get('/exports')
    },
    //notifications
    getNotifications() {
        return new FCR().get('/notifications/user')
    },
    postNotifications(NewNotification) {
        return new FCR().post('/notifications', NewNotification)
    },
    putNotifications(data) {
        return new FCR().put('/notifications/' + data._id, data);
    },


    //FIDS
    createAirline(data) {
        return new FCR().post('/fids/airlines', data);
    },
    createFlight(data) {
        return new FCR().post('/fids/flight', data);
    },
    deleteAirline(id) {
        return new FCR().delete("/fids/airlines/" + id);
    },
    deleteFlight(id) {
        return new FCR().delete('/fids/flight/' + id)
    },
    readAirlines() {
        return new FCR().get('/fids/airlines');
    },
    readAirportInfo(code) {
        return new FCR().get('/fids/airport/code/' + code);
    },
    readFlight(id) {
        return new FCR().get('/fids/flight/' + id);
    },
    readFlights() {
        return new FCR().get('/fids/flights');
    },
    updateAirline(id, data) {
        return new FCR().put('/fids/airlines/' + id, data);
    },
    updateFlight(id, data) {
        return new FCR().put('/fids/flight/' + id, data);
    },

    //Notifications
    getAllNotifications() {
        return new FCR().get('/notifications');
    },
    deleteNotification(notification) {
        return new FCR().delete('/notifications/' + notification._id);
    },
    editNotification(notification) {
        return new FCR().put('/notifications/' + notification._id, notification)
    },
    postNotification(notification) {
        return new FCR().post('/notifications', notification)
    },

    //Google Maps
    getCoordinatesFromAddress(address) {
        return FCR().get('/api/google/maps/geocode/address?address=' + address);
    }
}
